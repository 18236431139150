
body{
    background: #333332;
}

/*<======= [Topo]========>*/
.icon-top{
    color: #999c9e!important;
}
/*<===== [fim-Menu]======>*/


/*<======= [Menu]========>*/

.logo-softcom{
    padding-left: 18px;
    padding-right: 18px;
}

/*Logo menu hidden*/
body.mini-navbar .nav-header {
  background-color: orange!important;
}

/* Menu escondido */
.mini-navbar .nav-second-level {
    background: #333332!important;
}

/* Menu hidden aberto background */
.mini-navbar li.active .nav-second-level{
    background: #333332!important;
}

/* Menu com class active */
.nav > li.active > a {
  color: orange!important;
}

/* background da barra menu */
.navbar-default, .navbar-static-side{
    background: #252524!important;
    min-height: 100%;
}

#wrapper{
    background: #252524!important;
}

/* Menu cor fonte */
.nav > li > a{
    color: #fff!important;
}

/* Menu cor icone */
.nav > li > a > i{
    color: orange;
}

/* Menu houver */
.navbar-default .nav > li > a:hover{
    color: orange!important;
    background-color: #333332;
}

/* Menu clicado */
.navbar-default .nav > li > a:focus {
    color: orange!important;
    background-color: #333332!important;
}

/* Menu aberto cor borda e background */
.nav > li.active {
  border-left: 4px solid orange!important;
  background: #333332!important;
}

/*Menu especial*/
.navbar-default .special_link a{
    background: #F57C00;
}

/*Menu especial*/
.navbar-default .special_link a:hover{
    background: #F57C00!important;
    color: #fff!important;
}

/*Icones do topo*/
.icon-special{
    color: #fff!important;
}

.form-control{
    display: block;
    width: 100%;
    height: 30px;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control{
    border: 1px solid #e5e6e7;
    margin-right: 10px;
}

.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

.submit{float: left; width: 100%; background: #eee; padding: 5px 0; margin-top: 10px; text-align: center; border-top: 1px solid #ccc;}

.form-control:focus {
  border-color: orange!important;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(233,206,102, .6);
          box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(233,206,102, .6);
}
/*<======= [fim-Menu]========>*/

label {
    margin-bottom: 0px!important;
}

/*<======= [footer]========>*/

.rodape{
    /*margin-top: 20px;*/
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    position: relative;
    right: 0;
    background: #fff;
}

/* Social */
.ul-media-footer-right{
    width: 200px;
    margin-right: 20px;
    list-style: none;
    display: block;
    float: right;
}

.ul-media-footer-left{
    list-style: none;
    display: block;
    float: left;
}

.ul-media-footer a{
    display: block;
}

li > .circle-footer-media{
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background: #000;
    text-align: center;
    color: orange;
    font-size: 25px;
    padding-top: 6px;
    list-style: none;
    float: left;
    margin-left: 5px;
}

.social{
    border-bottom: 1px solid #f2f2f2;
}

.address{
    background: #FAFAFA;
    height: auto;
    padding: 10px;
}

.certificacao{
    float: left;
    margin-left: 20px;
}
/*<======= [fim-Footer]========>*/


/* ESTILOS FORA DO LAYOUT */
.ibox-title{
    border-color: orange;
}

.ibox {
    box-shadow: 1px 10px 10px -13px;
}

.table-responsive{
    border: none;
}

/* ESTILI PERSONALIZADO PARA PAGINAS INDEX AJUDA */
/* Cor borda ajuda */
.helper-view {
    border-color: #23c6c8!important;
}

.box-index-help{
    text-align: center;
    padding-top: 10px;
}

.box-index-help h2{
    color: silver;
}

.box-index-help span{
    color: orange;
}

.box-index-help p{
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 1.8;
    padding: 10px;
    text-align: justify!important;
    background: #f2f2f2;
}

/* Login */
.loginColumns{
    padding-top: 50px;
}

.box-login{
-webkit-box-shadow: 0px 7px 24px 0px rgba(50, 50, 50, 0.05);
-moz-box-shadow:    0px 7px 24px 0px rgba(50, 50, 50, 0.05);
box-shadow:         0px 7px 24px 0px rgba(50, 50, 50, 0.05);
}

/*<======= [inicio - geral]========>*/
.checkbox {margin-top: 0; margin-bottom: 0;}
.screen{
    display: none !important;
}

/*<======= [fim - geral]========>*/

.navbar-fixed-top, .navbar-static-top {
    background: #fff;
}
.ibox-heading{
    background: #fafafa;
}

.wrapper-content{
    min-height: 100%;
}

.font-bold open{
    color: #999c9e!important;
}

 /*<================[inicio Tabs]===============>*/

.nav.nav-tabs li {
    border-top: 1px solid #eee;
    border-right: none;
    padding-right: 2px;
    border-top: none;
}

.nav-tabs > li > a{
    color: #676A6C!important;
    background: #eee;
    border-radius: 0 5px 0 0 !important;
}

/* Tabs cor icone */
.nav-tabs > li > a > i{
    color: orange;
}

/* Tabs houver */
.nav-tabs > li > a:hover{
    color: orange !important;
    background-color: #eee;
}

/* Tabs clicado */
.nav-tabs > li > a:focus {
    color: #676A6C!important;
} 

/* Tabs aberto cor borda e background */
.nav-tabs > li.active {
    border-left: 4px solid orange!important;
    background: #fff!important;
}

.nav-tabs > li.active > a:hover {
    color: orange;
}

.nav-tabs > li.active > a {
    color: #676A6C!important;
    border-bottom-color : #fff;
    background-color: #fff;
}

.tab-pane {margin-top: 25px;}
 /*<================[fim Tabs]===============>*/

 /*<================[semantic]===============>*/
.orange {color: orange}

 /*<================[fim semantic]===============>*/


 /*<================[jquery ui]===============>*/
 .datepicker-days > .active {
    background-color: #F8AC59 !important;
    border-color: #F8AC59 !important;
 }

.datepicker-dropdown{z-index:2051 !important;}
 /*<================[fim jquery ui]===============>*/

 .disabled{
    pointer-events: none !important;
    cursor: default !important;
 }

 /*<================[form validate]===============>*/
.help-block {
    position: absolute;
    color: #a94442;
    z-index: 1000;
    font-size: 10px;
}

.help-block-mt {
    margin-top: 35px !important;
}
 /*<================[fim form validate]===============>*/

 /*<================[inicio form autocomplete]===============>*/
 .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a{
     background-color: #F8AC59 !important;
 }

 .typeahead-list, .typeahead-dropdown {
     min-width: 100%;
     width: auto;
 }

.typeahead-list.empty > li > a {
    color: #000000 !important;
}

.typeahead-list.empty > li > a:hover,
/*.typeahead-list.empty > li > a,*/
.typeahead-list.empty > li > a:focus,
.typeahead-list.empty > li.active > a {
    background-color: #F8AC59 !important;
    color: #FFFFFF !important;
    cursor: pointer;
}

.ui-widget-overlay {z-index: 10001 !important;}

.modal-header .label {
    float: none;
    margin-left: 0;
}

input::-webkit-inner-spin-button, input::-webkit-outer-spin-button{
   -webkit-appearance: none;
    margin: 0;
}

@media all and (max-width: 540px) {
    .sweet-alert {
        top: 30% !important;
    }
}

.cor-sucesso {
    background: #00FF00 !important;
}
.cor-alerta {
    background: #f88904 !important;
}
.cor-erro {
    background: #ec0c00 !important;
}