@media print {
    * {
        background:transparent !important;
        color:#000 !important;
        text-shadow:none !important;
        filter:none !important;
        -ms-filter:none !important;
    }
 
    body {
        margin:0;
        padding:0;
        line-height: 1.4em;
        color: #000;
        margin-bottom: 20px;
    }
    
    h1 {
        font-size: 24pt;
    }

    h2 {
        font-size: 18pt;
    }

    h3 {
        font-size: 14pt;
    }
    
    
    @page {
        margin: 0.2cm;
    }
    
    //Style
    .ibox {
        margin: 0!important;
    }
    
    #menu{
        display: none!important;
    }
    
    #topo-notificacoes{
        display: none!important;
    }
    
    button {
        display: none !important;
        outline: none !important;
    }
    
    .ibox-tools{
        display: none !important;
    }
    
    .ibox-title{
        border: none !important;
    }
    
    a{
        display: none !important;
    }
    
    .cabecalho-print{
        float: left;
        height: 70px;
        width: 100%;
        margin-top: -10px;
        border-bottom: 2px solid silver;
    }
    
    .logo-empresa{
        margin-top: 15px;
        float: left;
        width: 150px;
    }
    
    .dados-empresa{
        font-size: 11px;
        float: left;
        padding: 5px 0px 5px 10px;
    }
    
    .dados-impressao{
        font-size: 11px;
        float: right;
        padding: 5px 0px 5px 10px;
    }
    
    .no-print{
        display: none!important;
    }
    
    .screen {
        display: block !important;
    }
    
    .filter-print {
        width: 146px;
        float: left;
    }
    
    .wrapper {
        margin-left: -70px;
        width: 108%;
    }
    
    #page-wrapper{
        outline: 1px solid red;
    }
}