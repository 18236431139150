/*
 * Estilos do Autocomplete
 */
.autocompleter {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
 /*
 * Estilos do Comentario do campo (field)
 */
label {
    margin-bottom: 2px !important;
}
.field.comment {
    background: #f8ac59;
    border-radius: 4px;
    padding: 0.15em 0.5em;
    margin-left: 0.4em;
    cursor: pointer;
    color: white;
    display: inline-block;
}
/**
 * Estilo overlay
 */
.laravel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    filter:alpha(opacity=50);
    -moz-opacity:0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
    z-index: 10000;
    margin: 0 auto;
    vertical-align: middle !important;
    color: #ffffff;
}
.laravel-overlay > div {
    position: absolute;
    top: 40%;
    left: 50%;
}

/**
 * Estilos objeto GroupFormPanel
 */
.group_panel.ibox {
    margin-bottom: 20px;
    border: 1px solid #e7eaec !important;
    border-radius: 4px;
}
.group_panel .ibox-tools a.collapse-link i {
    padding-top: 1em;
}
.group_panel .ibox-title {
    margin: 0;
    padding-bottom: 0;
}
.group_panel .ibox-tools a.collapse-link i {
    padding-top: 0.75em;
}

/**
 * Estilos da lista do autocomplete
 */
ul.typeahead-list {
    margin-bottom: -1em;
    overflow-y:auto;
    max-height: 500px;
}

.typeahead-list .list-description {
    display: block;
}

.typeahead-list li:hover .orange {
    color: white;
}

.typeahead-list .list-title {
    font-weight: bolder;
    display: block;
}
